<template lang="pug">
.product-card(:style="'background-image: url('+settings.welcome_drinks_image+');'" v-for="p in product.welcome_drinks" v-if="product")
  h3 {{p.name}}
  p {{p.description}}
  p p.P. 
    strong {{toCurrency(p.price)}}
  .input-wrapper
    input(type='hidden' name='order_item[welcome_drinks_product_id]' value="")
    input(type='checkbox' name='order_item[welcome_drinks_product_id]' id='drinks_welcome' v-model="checked" :value="p.id")
    label.btn.is--check.is--primary.is--inverted(for='drinks_welcome')
      div
        svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
          path(d='M11.25 12.75H5.5V11.25H11.25V5.5H12.7499V11.25H18.5V12.75H12.7499V18.5H11.25V12.75Z')
        span Hinzufügen
      div
        svg(width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z')
            span Hinzugefügt

</template>

<script>
export default {
  components: {
  },
  props: [
    "settings",
    "product",
    "order"
  ],
  data () {
    return {
      checked: false,
    }
  },
  mounted () {
    if (this.order.welcome_drinks_product_id) this.checked = true
  },
  watch: {
  },
  computed: {
  },
  methods: {
    toCurrency(x){
      return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x)
    }
  }
}
</script>
