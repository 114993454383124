<template lang="pug">
div
  .columns.column-count-3.gap--small
    .column
      .input-wrapper.is--select
        label.is--floating.is--active(for='ticket_category') Kategorie 
          i *
        select(id='ticket_category' name='order_item[ticket_category]' required v-model="category")
          option(v-for="c in settings.categories" :value='c.id') {{c.name}}
        svg(width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M12 15.375L6 9.37498L7.4 7.97498L12 12.575L16.6 7.97498L18 9.37498L12 15.375Z')
      .icon-text.font-size--small.fill--white
        svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
          path(d='M11.25 16.75H12.75V11H11.25V16.75ZM12 9.28845C12.2288 9.28845 12.4207 9.21105 12.5755 9.05625C12.7303 8.90145 12.8077 8.70963 12.8077 8.48078C12.8077 8.25194 12.7303 8.06012 12.5755 7.9053C12.4207 7.7505 12.2288 7.6731 12 7.6731C11.7711 7.6731 11.5793 7.7505 11.4245 7.9053C11.2697 8.06012 11.1923 8.25194 11.1923 8.48078C11.1923 8.70963 11.2697 8.90145 11.4245 9.05625C11.5793 9.21105 11.7711 9.28845 12 9.28845ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76664 19.225 7.87498 17.675 6.32498C16.125 4.77498 14.2333 3.99998 12 3.99998C9.76664 3.99998 7.87498 4.77498 6.32498 6.32498C4.77498 7.87498 3.99998 9.76664 3.99998 12C3.99998 14.2333 4.77498 16.125 6.32498 17.675C7.87498 19.225 9.76664 20 12 20Z')
        .text-content
          p Zur Kategorieübersicht kommen Sie 
            a(href='#saalplan') hier
            | .
    .column(style="margin-top: -0.5em;")
      input(type='hidden' name='order_item[date]' :value="date.toISOString().split('T')[0]" v-if="date")
      input(type='hidden' name='order_item[product_id]' :value="product.id" v-if="product")
      .counter
        button.btn.is--icon.is--inverted(type='button' @click="count != 0 ? count-- : null")
          svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
            path(d='M5.5 12.7499V11.25H18.5V12.7499H5.5Z')
        .input-wrapper
          label.is--floating.is--active(for='quantity') Kartenanzahl 
            i *
          input(type='number' id='quantity' name='order_item[quantity]' min='1' max='100' step='1' v-model="count" required)
        button.btn.is--icon.is--inverted(type='button'  @click="count++")
          svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
            path(d='M11.25 12.75H5.5V11.25H11.25V5.5H12.7499V11.25H18.5V12.75H12.7499V18.5H11.25V12.75Z')
      details.is--small
        summary
          svg(width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M12 15.375L6 9.37498L7.4 7.97498L12 12.575L16.6 7.97498L18 9.37498L12 15.375Z')
          span zzgl. Kinder
          .tooltip
            button.btn.is--icon.child-button-tooltip(type='button')
              svg.tooltip-icon(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
                path(d='M11.25 16.75H12.75V11H11.25V16.75ZM12 9.28845C12.2288 9.28845 12.4207 9.21105 12.5755 9.05625C12.7303 8.90145 12.8077 8.70963 12.8077 8.48078C12.8077 8.25194 12.7303 8.06012 12.5755 7.9053C12.4207 7.7505 12.2288 7.6731 12 7.6731C11.7711 7.6731 11.5793 7.7505 11.4245 7.9053C11.2697 8.06012 11.1923 8.25194 11.1923 8.48078C11.1923 8.70963 11.2697 8.90145 11.4245 9.05625C11.5793 9.21105 11.7711 9.28845 12 9.28845ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76664 19.225 7.87498 17.675 6.32498C16.125 4.77498 14.2333 3.99998 12 3.99998C9.76664 3.99998 7.87498 4.77498 6.32498 6.32498C4.77498 7.87498 3.99998 9.76664 3.99998 12C3.99998 14.2333 4.77498 16.125 6.32498 17.675C7.87498 19.225 9.76664 20 12 20Z')
            .tooltip-wrapper
              button.btn.is--tertiary.is--toggle.is--icon(type='button')
                svg(width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
                  path(d='M6.40002 18.6538L5.34619 17.6L10.9462 12L5.34619 6.40002L6.40002 5.34619L12 10.9462L17.6 5.34619L18.6538 6.40002L13.0538 12L18.6538 17.6L17.6 18.6538L12 13.0538L6.40002 18.6538Z')
              p Für Kinder bis 12 Jahre sind 50% des regulären Preises zu zahlen. Die Anzahl der Kinderkarten zählt zusätzlich zu den regulären Karten.
        .counter.toggle-content
          button.btn.is--icon.is--inverted(type='button' @click="childCount != 0 ? childCount-- : null")
            svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
              path(d='M5.5 12.7499V11.25H18.5V12.7499H5.5Z')
          .input-wrapper
            label.is--floating.is--active(for='ticket_count_children') Anzahl Kinderkarten
            input(type='number' id='ticket_count_children' name='order_item[ticket_count_children]' min='0' max='100' step='1' v-model="childCount" required)
          button.btn.is--icon.is--inverted(type='button'  @click="childCount++")
            svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
              path(d='M11.25 12.75H5.5V11.25H11.25V5.5H12.7499V11.25H18.5V12.75H12.7499V18.5H11.25V12.75Z')
    .column
      .input-wrapper2
        label.is--floating.is--active(for='ticket_date') Veranstaltungstag 
          i *
        vue-date-picker(
          v-model="date"
          locale="de"
          :enable-time-picker="false"
          :month-change-on-scroll="false"
          :auto-apply="true"
          :clearable="false"
          :start-date="startDate"
          :min-date="startDate"
          :max-date="maxDate"
          :allowed-dates="allowedDates"
          :format="format"
        )
        svg.input-icon(width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M9,10V12H7V10H9M13,10V12H11V10H13M17,10V12H15V10H17M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H6V1H8V3H16V1H18V3H19M19,19V8H5V19H19M9,14V16H7V14H9M13,14V16H11V14H13M17,14V16H15V14H17Z')
      .icon-text.font-size--small.fill--white
        svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
          path(d='M11.25 16.75H12.75V11H11.25V16.75ZM12 9.28845C12.2288 9.28845 12.4207 9.21105 12.5755 9.05625C12.7303 8.90145 12.8077 8.70963 12.8077 8.48078C12.8077 8.25194 12.7303 8.06012 12.5755 7.9053C12.4207 7.7505 12.2288 7.6731 12 7.6731C11.7711 7.6731 11.5793 7.7505 11.4245 7.9053C11.2697 8.06012 11.1923 8.25194 11.1923 8.48078C11.1923 8.70963 11.2697 8.90145 11.4245 9.05625C11.5793 9.21105 11.7711 9.28845 12 9.28845ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76664 19.225 7.87498 17.675 6.32498C16.125 4.77498 14.2333 3.99998 12 3.99998C9.76664 3.99998 7.87498 4.77498 6.32498 6.32498C4.77498 7.87498 3.99998 9.76664 3.99998 12C3.99998 14.2333 4.77498 16.125 6.32498 17.675C7.87498 19.225 9.76664 20 12 20Z')
        .text-content
          p Zur Preisübersicht kommen Sie 
            a(href='#infos') hier
            | .

  .icon-text.is--warning(v-if="(count + childCount) >= 50")
    svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
      path(d='M11.25 16.75H12.75V11H11.25V16.75ZM12 9.28845C12.2288 9.28845 12.4207 9.21105 12.5755 9.05625C12.7303 8.90145 12.8077 8.70963 12.8077 8.48078C12.8077 8.25194 12.7303 8.06012 12.5755 7.9053C12.4207 7.7505 12.2288 7.6731 12 7.6731C11.7711 7.6731 11.5793 7.7505 11.4245 7.9053C11.2697 8.06012 11.1923 8.25194 11.1923 8.48078C11.1923 8.70963 11.2697 8.90145 11.4245 9.05625C11.5793 9.21105 11.7711 9.28845 12 9.28845ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76664 19.225 7.87498 17.675 6.32498C16.125 4.77498 14.2333 3.99998 12 3.99998C9.76664 3.99998 7.87498 4.77498 6.32498 6.32498C4.77498 7.87498 3.99998 9.76664 3.99998 12C3.99998 14.2333 4.77498 16.125 6.32498 17.675C7.87498 19.225 9.76664 20 12 20Z')
    .icon-text-content
      p 
        | Bitte kontaktieren Sie bei einer Anfrage über 50 Tickets gerne unser Ticketbüro unter der Ticket-Hotline: {{phone}} oder nutzen Sie unser  
        a(href="/kontakt") individuelles Kontaktformular
        | .
  .icon-text.is--warning(v-else-if="date && !product && !loading")
    svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
      path(d='M11.25 16.75H12.75V11H11.25V16.75ZM12 9.28845C12.2288 9.28845 12.4207 9.21105 12.5755 9.05625C12.7303 8.90145 12.8077 8.70963 12.8077 8.48078C12.8077 8.25194 12.7303 8.06012 12.5755 7.9053C12.4207 7.7505 12.2288 7.6731 12 7.6731C11.7711 7.6731 11.5793 7.7505 11.4245 7.9053C11.2697 8.06012 11.1923 8.25194 11.1923 8.48078C11.1923 8.70963 11.2697 8.90145 11.4245 9.05625C11.5793 9.21105 11.7711 9.28845 12 9.28845ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76664 19.225 7.87498 17.675 6.32498C16.125 4.77498 14.2333 3.99998 12 3.99998C9.76664 3.99998 7.87498 4.77498 6.32498 6.32498C4.77498 7.87498 3.99998 9.76664 3.99998 12C3.99998 14.2333 4.77498 16.125 6.32498 17.675C7.87498 19.225 9.76664 20 12 20Z')
    .icon-text-content
      p Leider sind an diesem Veranstaltungstag, in dieser Kategorie und mit der von Ihnen angeben Anzahlen, keine Plätze mehr verfügbar.
      p 
        | Bitte kontaktieren Sie unser Ticketbüro unter der Ticket-Hotline: {{phone}} oder nutzen Sie unser  
        a(href="/kontakt") individuelles Kontaktformular
        | .

      p(v-if="availableCategoryNames.length != 0")
        | Wir können Ihnen an diesem Tag noch Plätze in folgenden Kategorien anbieten:
        div(v-for="a in availableCategoryNames") {{a}}
  .icon-text.is--warning(v-if="(count + childCount) <= 0")
    svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
      path(d='M11.25 16.75H12.75V11H11.25V16.75ZM12 9.28845C12.2288 9.28845 12.4207 9.21105 12.5755 9.05625C12.7303 8.90145 12.8077 8.70963 12.8077 8.48078C12.8077 8.25194 12.7303 8.06012 12.5755 7.9053C12.4207 7.7505 12.2288 7.6731 12 7.6731C11.7711 7.6731 11.5793 7.7505 11.4245 7.9053C11.2697 8.06012 11.1923 8.25194 11.1923 8.48078C11.1923 8.70963 11.2697 8.90145 11.4245 9.05625C11.5793 9.21105 11.7711 9.28845 12 9.28845ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76664 19.225 7.87498 17.675 6.32498C16.125 4.77498 14.2333 3.99998 12 3.99998C9.76664 3.99998 7.87498 4.77498 6.32498 6.32498C4.77498 7.87498 3.99998 9.76664 3.99998 12C3.99998 14.2333 4.77498 16.125 6.32498 17.675C7.87498 19.225 9.76664 20 12 20Z')
    .icon-text-content
      p Anzahl muss mindestens einen Platz beinhalten.
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'; 
export default {
  components: {
    VueDatePicker
  },
  props: [
    "settings",
    "order",
    "standalone",
    "phone"
  ],
  data () {
    return {
      date: null,
      count: 1,
      childCount: 0,
      category: this.settings.categories[0].id,
      startDate: new Date(2024, 11, 1),
      maxDate: new Date(2025, 0, 31),
      allowedDates: [new Date(2024, 11, 1), new Date(2025, 0, 1)],
      dates: [],
      availableCategories: [],
      product: null,
      loading: true
    }
  },
  mounted () {
    if (this.order.date) this.date = new Date(this.order.date+" 12:00")
    if (this.order.quantity) this.count = this.order.quantity
    if (this.order.ticket_count_children) this.childCount = this.order.ticket_count_children
    if (this.order.ticket_category) this.category = this.order.ticket_category

    this.$emit('update:buttonDisabled', true)
    this.$emit('update:count', this.count+this.childCount)
    this.fetchDates()
  },
  watch: {
    count(){
      this.$emit('update:count', this.count+this.childCount)
      this.fetchDates()
    },
    childCount(){
      this.$emit('update:count', this.count+this.childCount)
      this.fetchDates()
    },
    category(){
      this.fetchDates()
    },
    date(){
      this.fetchDates()
      this.setProduct()
    },
    dates(){
      this.setProduct()
    } 
  },
  computed: {
    availableCategoryNames(){
      return this.availableCategories.map(x => this.settings.categories.find(y => y.id == x) ? this.settings.categories.find(y => y.id == x).name : null).filter(x => x)
    }
  },
  methods: {
    setProduct(){
      if (this.date){
        let date = this.date
        if (this.standalone){
          this.product = this.dates.find(x => x.date_1day == date.toISOString().split('T')[0]) || this.dates.find(x => x.date == date.toISOString().split('T')[0])
        }else{
          this.product = this.dates.find(x => x.date == date.toISOString().split('T')[0])
        }
        if ((this.count + this.childCount) >= 50 || (this.count + this.childCount) <= 0){
          this.$emit('update:buttonDisabled', true)
        } else {
          this.$emit('update:buttonDisabled', !this.product)
        }
        this.$emit('update:product', this.product)
      } else {
        this.$emit('update:buttonDisabled', true)
      }
    },
    fetchDates(){
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      const params = {
        count: this.count + this.childCount,
        category: this.category,
        c_date: this.date
      }
      const options = {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      fetch("/api/get/dates.json", options).then(x => x.json()).then((data) => {
        this.loading = false
        this.dates = data.dates
        this.allowedDates = data.dates.map( x => x.date )
        this.availableCategories = data.categories
        if (data.dates[0]){
          this.startDate = data.dates[0].date
          this.maxDate = data.dates.slice(-1).date
        }
      })
    },
    format(date){
      function pad(n, len) {
        return (new Array(len + 1).join('0') + n).slice(-len);
      }

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${pad(day, 2)}.${pad(month, 2)}.${year}`;
    }
  }
}
</script>
