<template lang="pug">
div
  .steps(v-if="!_step")
    span.step(:class="activeStepClass(1)") 1
      span.label Allgemein
    span.step(:class="activeStepClass(2)+' '+activeStepClass(3)") 2
      span.label Getränke
    span.step(:class="activeStepClass(4)") 3
      span.label Menüs
    span.step(:class="activeStepClass(5)") 4
      span.label Abschluss
  .multistep-tab(:class="activeTabClass(1)"): step1(:html="html" :phone="phone" :standalone="_step == 5" :order="order" :settings="settings" v-model:buttonDisabled="buttonDisabled" v-model:product="product" v-model:count="count")
  .multistep-tab(:class="activeTabClass(2)"): step2(:html="html" :order="order" :settings="settings" :product="product")
  .multistep-tab(:class="activeTabClass(3)"): step3(:html="html" :order="order" :settings="settings" :product="product")
  .multistep-tab(:class="activeTabClass(4)"): step4(:html="html" :standalone="_step == 4" v-if="_step != 5" :order="order" :settings="settings" :product="product" :count="count" v-model:buttonDisabled="buttonDisabled")
  .multistep-tab(:class="activeTabClass(5)"): step5(:html="html" :standalone="_step == 5" v-if="_step != 4" :order="order" :settings="settings" :product="product" :count="count")
  .navigation(v-if="!_step")
    button#prev-btn.btn.is--inverted.is--secondary(type='button' @click="prevStep" v-if="step != 1")
      span Zurück
    button#next-btn.btn.is--inverted(type='button' @click="nextStep" v-if="step != 5" :class="buttonDisabled ? 'is--disabled' : ''")
      span Weiter

</template>

<script>
import Step1 from './Steps/Step1.vue'
import Step2 from './Steps/Step2.vue'
import Step3 from './Steps/Step3.vue'
import Step4 from './Steps/Step4.vue'
import Step5 from './Steps/Step5.vue'

export default {
  components: {Step1, Step2, Step3, Step4, Step5},
  props: [
    "settings",
    "order",
    "html",
    "phone",
    "_step"
  ],
  data () {
    return {
      count: 1,
      product: null,
      step: 1,
      buttonDisabled: false
    }
  },
  mounted () {
    if (this._step) this.step = this._step
  },
  watch: {
  },
  computed: {
  },
  methods: {
    prevStep(){
      if (this.buttonDisabled) return false
      document.getElementById('ticketShop').scrollIntoView();
      this.step--
    },
    nextStep(){
      if (this.buttonDisabled) return false
      document.getElementById('ticketShop').scrollIntoView();
      this.step++
    },
    activeTabClass(i){
      if(i != this.step) return "is--hidden"
    },
    activeStepClass(i){
      if(i === this.step) return "is--active"
      if(i < this.step) return "is--finished"
    }
  }
}
</script>
