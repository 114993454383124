import { createApp } from 'vue'
import App from './apps/TicketShop/App.vue'

document.addEventListener("turbo:load", () => {
  const mountEl = document.querySelector("#ticketShop");
  if (mountEl){
    const app = createApp(App, {
      settings: JSON.parse(mountEl.dataset.settings),
      order: JSON.parse(mountEl.dataset.order),
      _step: mountEl.dataset.step,
      phone: mountEl.dataset.phone,
      html: mountEl.innerHTML,
    })
    app.mount('#ticketShop')
  }

  const mountEl2 = document.querySelector("#ticketShop2");
  if (mountEl2){
    const app2 = createApp(App, {
      settings: JSON.parse(mountEl2.dataset.settings),
      order: JSON.parse(mountEl2.dataset.order),
      _step: mountEl2.dataset.step,
      phone: mountEl.dataset.phone,
      html: mountEl.innerHTML,
    })
    app2.mount('#ticketShop2')
  }

});

